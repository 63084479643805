const Menu = [
    {
      label: "Home",
      pathname: "/"
    },
    {
      label: "About",
      pathname: "/about"
    },
    {
      label: "Shop",
      pathname: "/shop"
    },
    {
      label: "Pro Tips",
      pathname: "/protips"
    },
    {
      label: "Contact",
      pathname: "/contact"
    }
  
  ];
  
  export default Menu;